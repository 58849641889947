<template>
  <div class="all css-helper">
    <div style="height: 10px; width: 100%"></div>
    <div class="zhuCeClass">
      <span
        class="userName"
        @click="username"
        :class="acti == 1 ? 'fontwei' : ''"
      >
        用户名注册
      </span>
      <span class="line">|</span>
      <span class="shouce" @click="Phone" :class="acti == 2 ? 'fontwei' : ''">
        手机快捷注册
      </span>
    </div>
    <div class="zhuce" v-if="acti == 1">
      <div class="from">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="name">
            <el-input
              placeholder="数字、字母、下划线、汉字"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              placeholder="6-20位字母加数字组合"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="twopassword">
            <el-input
              type="password"
              placeholder="确认密码"
              v-model="ruleForm.twopassword"
            ></el-input>
          </el-form-item>

          <el-form-item id="yanzheng" label="验证码" prop="yanzheng">
            <el-input
              placeholder="请输入验证码"
              v-model="ruleForm.yanzheng"
            ></el-input>
            <img
              class="yanzhengImg cursor-pointer"
              :src="codeImage"
              alt=""
              @click="imgCode"
            />
          </el-form-item>

          <el-form-item prop="checkbox1">
            <div class="xieyi">
              <el-checkbox
                class="fuxuan"
                id="zhanghao"
                type="checkbox"
                name=""
                v-model="checkbox1"
              />
              <span class="tongyi" style="color: #999999">
                同意
                <span class="colorZong"> 《用户协议及隐私政策》 </span>
                (若不同意，不可注册)
              </span>
              <div class="link"></div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button id="zhuce" @click="zhuce('ruleForm')" type="primary"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="Phonezhuce" v-if="acti == 2">
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        label-width="120px"
        class="demo-ruleForm from"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="输入11位手机号码"
            v-model="ruleForm2.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="yanzheng">
          <el-input
            placeholder="输入图片中对应的数字"
            v-model="ruleForm2.yanzheng"
          ></el-input>
          <img
            class="yanzhengImgtwo cursor-pointer"
            :src="codeImage"
            @click="imgCode"
            alt=""
          />
        </el-form-item>
        <el-form-item label="手机验证码" prop="PhoneYanzheng">
          <el-input
            placeholder="输入手机验证码"
            v-model="ruleForm2.PhoneYanzheng"
          ></el-input>
          <div class="shouji" @click="obtaincode()">{{ smsButton.text }}</div>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input
            placeholder="6-20位字母加数字组合"
            type="password"
            v-model="ruleForm2.pass"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            placeholder="请二次确认密码"
            type="password"
            v-model="ruleForm2.checkPass"
          ></el-input>
        </el-form-item>
        <el-form-item class="xieyi-outer" prop="phoneRegisterCheckbox">
          <div class="xieyi">
            <el-checkbox
              class="fuxuan"
              type="checkbox"
              name=""
              v-model="phoneRegisterCheckbox"
            />
            <span class="tongyi" style="color: #999999">
              同意
              <span class="colorZong"> 《用户协议及隐私政策》 </span>
              (若不同意，不可注册)
            </span>
            <div class="link"></div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button id="zhuce" type="primary" @click="submitForm('ruleForm2')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer";
import SmsButtonAgent from "../components/smsButtonAgent";
export default {
  data() {
    //验证用户名
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名不能为空"));
      } else if (this.usernameMsg !== "") {
        return callback(new Error(this.usernameMsg));
      } else {
        callback();
      }
    };
    //验证密码
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else if (value.length < 6 || value.length > 20) {
        return callback(new Error("请输入正确的密码格式！"));
      } else {
        callback();
      }
    };
    //验证重复密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== (this.ruleForm2.pass || this.ruleForm.password)) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //验证图片验证码
    var checkImgCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (this.imgCodeMsg !== "") {
        callback(new Error(this.imgCodeMsg));
      } else {
        callback();
      }
    };
    //验证手机号码
    var checkPhone = (rule, value, callback) => {
      var reg_phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!reg_phone.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else if (this.phoneMsg !== "") {
        callback(new Error(this.phoneMsg));
      } else {
        callback();
      }
    };
    //验证手机验证码
    var checkPhoneCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机验证码"));
      } else if (this.phoneCodeMsg !== "") {
        callback(new Error(this.phoneCodeMsg));
      } else {
        callback();
      }
    };
    ///验证手机号注册同意协议
    var checkPhoneRegisterCheckbox = (rule, value, callback) => {
      value = this.phoneRegisterCheckbox;
      if (value === false) {
        callback(new Error("未同意协议及隐私政策"));
      } else {
        callback();
      }
    };
    //验证用户名注册同意协议
    var checkcheckbox1 = (rule, value, callback) => {
      value = this.checkbox1;
      if (value === false) {
        callback(new Error("未同意协议及隐私政策"));
      } else {
        callback();
      }
    };
    return {
      imgCodeCheckMsg1: "",
      imgCodeCheckMsg2: "",
      phoneMsg: "",
      phoneCodeMsg: "",
      usernameMsg: "",
      imgCodeMsg: "",
      phoneRegisterCheckbox: true,
      checkbox1: true,
      smsButton: SmsButtonAgent(),
      codeImage: "",
      codesubstr: "",
      MYcode: "",
      acti: 1,
      ruleForm: {
        name: "",
        password: "",
        twopassword: "",
        yanzheng: "",
        checkbox1: true,
      },
      ruleForm2: {
        phone: "",
        yanzheng: "",
        PhoneYanzheng: "",
        pass: "",
        checkPass: "",
        phoneRegisterCheckbox: true,
      },
      //用户名注册验证
      rules: {
        name: [{ required: true, validator: checkName, trigger: "blur" }],
        password: [
          { required: true, validator: checkPassword, trigger: "blur" },
        ],
        twopassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        yanzheng: [
          { required: true, validator: checkImgCode, trigger: "blur" },
        ],
        checkbox1: [
          { required: true, validator: checkcheckbox1, trigger: "blur" },
        ],
      },
      //手机号注册验证
      rules2: {
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        yanzheng: [
          { required: true, validator: checkImgCode, trigger: "blur" },
        ],
        PhoneYanzheng: [
          { required: true, validator: checkPhoneCode, trigger: "blur" },
        ],
        pass: [{ required: true, validator: checkPassword, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        phoneRegisterCheckbox: [
          {
            required: true,
            validator: checkPhoneRegisterCheckbox,
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    Footer,
  },
  mounted() {
    this.imgCode();
  },
  methods: {
    imgCode() {
      let rand = Math.random().toString(12).substr(13);

      this.codesubstr = rand;

      this.$fetch(`/getVerify?imgcodeid=${rand}`).then((res) => {
        this.codeImage =
          "http://www.symuseum.cn/api/getVerify?imgcodeid=" + rand;
      });
      document.title = "注册";
    },
    username() {
      this.acti = 1;
      this.ruleForm2 = {
        phone: "",
        yanzheng: "",
        PhoneYanzheng: "",
        pass: "",
        checkPass: "",
      };
    },
    Phone() {
      this.acti = 2;
      this.ruleForm = {
        name: "",
        password: "",
        twopassword: "",
        yanzheng: "",
      };
    },

    submitForm(formName) {
      this.phoneCodeMsg = "";
      this.phoneMsg = "";
      this.imgCodeMsg = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let drr = {
            type: 1,
            source: "pc",
            image_verify_code: this.ruleForm2.yanzheng,
            imgcodeid: this.codesubstr,
            phone: this.ruleForm2.phone,
            verif_code: this.ruleForm2.PhoneYanzheng,
            password: this.ruleForm2.pass,
            confirm_password: this.ruleForm2.checkPass,
            // image_verify_code:
            // imgcodeid:
          };

          this.$post("/register", drr).then((res) => {
            if (res.code === 2003) {
              this.phoneCodeMsg = res.msg;
              this.$refs.ruleForm2.validateField("PhoneYanzheng");
            }
            if (res.code === 2002) {
              this.phoneMsg = res.msg;
              this.$refs.ruleForm2.validateField("phone");
            }
            if (res.code === 1) {
              this.showPopupMessage("注册成功", true);
              setTimeout(() => {
                localStorage.setItem("token", res.data.token);
                this.$router.push("/");
              }, 2000);
            }
          });
        }
      });
    },

    zhuce(formName) {
      this.usernameMsg = "";
      this.imgCodeMsg = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = {
            type: 3,
            source: "pc",
            image_verify_code: this.ruleForm.yanzheng,
            imgcodeid: this.codesubstr,
            username: this.ruleForm.name,
            password: this.ruleForm.password,
            confirm_password: this.ruleForm.twopassword,
          };
          this.$post("/register", arr).then((res) => {
            if (res.code === 1007) {
              this.imgCodeMsg = res.msg;
              this.$refs[formName].validate();
            }
            if (res.code === 2002) {
              this.usernameMsg = res.msg;
              this.$refs[formName].validate();
            }
            if (res.code === 1) {
              this.showPopupMessage("注册成功", true);
              setTimeout(() => {
                localStorage.setItem("token", res.data.token);
                this.$router.push("/");
              }, 2000);
            }
          });
        } else {
          return false;
        }
      });
    },
    obtaincode() {
      this.imgCodeMsg = "";
      this.$refs.ruleForm2.validateField("yanzheng");
      if (this.ruleForm2.phone === "" || this.ruleForm2.yanzheng === "") {
        this.$refs.ruleForm2.validateField(["phone", "yanzheng"]);
        return;
      }
      let cb = this.smsButton.request();
      if (cb === false) {
        return;
      }
      let query = {
        phone: this.ruleForm2.phone,
        type: "register",
        image_verify_code: this.ruleForm2.yanzheng,
        imgcodeid: this.codesubstr,
      };
      this.$post("/send_sms", query).then((res) => {
        if (res.code !== 1) {
          cb(false);
          if (res.code === 10017) {
            this.imgCodeMsg = res.msg;
            this.$refs.ruleForm2.validateField("yanzheng");
          }
        } else {
          cb(true);
          this.showPopupMessage(res.msg, true);
        }
      });
    },
  },
};
</script>






<style scoped>
>>> .el-form-item__error {
  top: 30%;
  left: 101%;
  white-space: nowrap;
  color: #f3244b;
  font-size: 14px;
}
.line {
  line-height: 100px;
  margin-left: 39px;
  font-size: 20px;
  font-weight: 450;
  color: #e1e6f0;
}
.all {
  width: 100%;
  background: url("../assets/zhuce/zhuceBack.png") no-repeat;
  background-size: 100% 100%;
}
.el-form-item {
  margin-bottom: 20px;
}
.zhuce {
  width: 1052px;
  height: 662px;
  background: url("../assets/zhuce/userNameZhuce.png") no-repeat;
  background-size: 1052px 662px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 80px 50px 50px 40px;
}
.from {
  width: 60%;
  margin: 0 auto;
}
.zhuCeClass {
  width: 344px;
  height: 90px;
  margin: 0 auto;
  font-size: 24px;
  color: #888888;
  font-weight: 350;
}
.zhuCeClass span {
  cursor: pointer;
}
.userName {
  float: left;
  line-height: 100px;
}
.shouce {
  float: right;
  line-height: 100px;
}
.Phonezhuce {
  width: 1052px;
  height: 662px;
  background: url("../assets/PhoneBack.png") no-repeat;
  background-size: 1052px 662px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 80px 50px 50px 40px;
  position: relative;
}
.fontwei {
  font-weight: 900;
  color: #333333;
}
#zhuce {
  width: 448px;
  height: 50px;
  background-color: #bd955d;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-top: 30px;
  font-size: 18px;
}
.xieyi {
  width: 100%;
}
.fuxuan {
  float: left;
  margin-top: 2px;
  margin-right: -55px;
}
.tongyi {
  float: left;
  display: block;
  margin-left: 20px;
  margin-top: 2px;
}

.yanzhengImg {
  height: 37px;
  width: 90px;
  margin-top: 7px;
  margin-bottom: 40px;
  clear: both;
  position: absolute;
  left: 348px;
}
.yanzhengImgtwo {
  height: 37px;
  width: 90px;
  margin-top: 8px;
  margin-bottom: 40px;
  clear: both;
  position: absolute;
  left: 348px;
}
.colorZong {
  color: #bd955d;
}

>>> .el-input__inner {
  width: 447px;
  height: 51px !important;
  background: rgb(249, 250, 252);
  border: 0px;
  border-radius: 10px;
}
.shouji {
  font-size: 14px;
  color: #b57205;
  position: absolute;
  left: 360px;
  top: 7px;
  cursor: pointer;
}

.el-form-item__label {
  padding: 6px 12px 0 0;
}
.xieyi-outer {
  margin-bottom: 0;
}
>>> .el-form-item__label {
  padding: 0px 30px 0 0 !important;
}
</style>
